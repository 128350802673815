import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Hero } from "../../containers"
import { Title } from "../../components"

const ClientProjectPage = ({ data }) => {
  const image = getImage(
    data.strapiClients.image.localFile.childImageSharp.gatsbyImageData
  )
  return (
    <section>
      <Hero image={image} title="Client Projects" />
      <div className="uk-container">
        <br />
        <br />
        <Title title={data.strapiClients.title} />
        <h3>ION Institution of Neurodiversity</h3>
        <h3>H&S Pepper Co</h3>
        <h3>Helix</h3>
        <h3>SkyPencil</h3>
        <br />
        <br />
      </div>
    </section>
  )
}

export default ClientProjectPage

export const query = graphql`
  {
    strapiClients {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
    }
  }
`
